import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import renderRoutes from 'react-router-config/renderRoutes';
import matchRoutes from 'react-router-config/matchRoutes';
import withRouter from 'react-router/withRouter';
import get from 'lodash/get';
import Cookies from 'js-cookie';
import matchContext from '../../../../config/manual_param_set';
import navContext from '../../../components/masthead/nav_data';
import log from '../../../../services/logger_service';
import { canUseDOM } from 'exenv';
import { Helmet } from 'react-helmet';
import ErrorBoundary from '../../../components/error_boundary/error_boundary';
import DefaultError from '../../../components/error_boundary/default_error';

import loadable from '@loadable/component';

const Analytics = loadable(() =>
  import('../../../components/analytics/analytics')
);
const Masthead = loadable(() =>
  import('../../../components/masthead/masthead')
);
const Recirc = loadable(() => import('../../../components/recirc/recirc'));
const Footer = loadable(() => import('../../../components/footer/footer'));
const Config = loadable(() => import('../../../components/config/config'));
const Script = loadable(() => import('../../../components/head/script'));
const PianoBottomBanner = loadable(() =>
  import('../../../components/piano/pianoBottomBanner')
);
const AdContainer = loadable(() =>
  import('../../../components/ad/AdContainer')
);

class Layout extends Component {
  constructor(props) {
    super(props);
    this.hasFooter = true;
    const routes = get(props, 'route.routes');
    this.pathname = get(props, 'location.pathname');
    this.isPreview =
      this.pathname.includes('/preview/') ||
      this.pathname.includes('/pvw/') ||
      this.pathname.includes('THIS-IS-A-PREVIEW-LINK');

    if (routes && this.pathname) {
      this.chunks = matchRoutes(routes, this.pathname).reduce(function matchMap(
        list,
        item
      ) {
        if (item.match.url.includes('/fcw')) {
          const splitUrl = item.match.url.split('/');
          const param = item.match.params.page
            ? splitUrl[splitUrl.length - 2]
            : splitUrl[splitUrl.length - 1];
          const newParam = `${param.replace('-', '_')}_advertorial`;
          // eslint-disable-next-line no-param-reassign
          item.match.params.tag = newParam;
        }

        // This will manually set the params in cases where we can't pass them in the url.
        matchContext.cases.forEach(foundCase => {
          if (item.match.url === foundCase.url) {
            // eslint-disable-next-line no-param-reassign
            item.match.params[foundCase.paramater] = foundCase.setTo;
          }
        });
        if (get(item, 'route.chunk')) {
          list.push(item.route.chunk);
        }
        return list;
      },
      []);
    } else {
      this.chunks = [];
    }

    this.state = {
      notification: {
        message: '',
        callback: () => {}
      },
      topNewsletterSignup: {
        show: false,
        callback: () => {}
      },
      hasFooter: true
    };

    this.hideFooter = this.hideFooter.bind(this);
  }

  componentDidMount() {
    this.setNewsletterSignupBar();
    this.setABTestCookie();
    this.setDailyCookie();
    this.hideFooter('/innovation-by-design/list');
    this.hideFooter('/apply/innovation-by-design');
  }

  setABTestCookie() {
    if (!Cookies.get('abgroup')) {
      const groupBucket = Math.floor(Math.random() * 20) + 1;
      const cookieValue = String(groupBucket);
      Cookies.set('abgroup', cookieValue, { expires: 1 });

      if (canUseDOM) {
        window.digitalData.user[0].segment.abGroup = cookieValue;
      }
    }

    const abgroupQuery = get(this.props, 'config.initialQueryParams.abgroup');
    if (abgroupQuery) {
      Cookies.set('abgroup', abgroupQuery, { expires: 1 });

      if (canUseDOM) {
        window.digitalData.user[0].segment.abGroup = abgroupQuery;
      }
    }
  }

  setNewsletterSignupBar() {
    // Don't show on the following pages...
    const hiddenPages = [
      '/company/gucci',
      '/90348127/fast-company-european-innovation-fest-to-feature-alec-ross-ngozi-okonjo-iweala-jessica-brillhart-and-more',
      '/apply/innovation-by-design',
      '/apply/world-changing-ideas',
      '/apply/most-innovative-companies',
      '/apply/best-workplaces-for-innovators',
      '/apply/brands-that-matter',
      '/most-innovative-companies'
    ];

    const currentPathName = window.location.pathname;
    if (hiddenPages.some(path => currentPathName.indexOf(path) >= 0)) {
      return;
    }

    if (!localStorage.getItem('hide-newsletter-signup-topnav')) {
      this.setState({
        topNewsletterSignup: {
          show: true,
          callback: () =>
            this.closeNewsletterBar('hide-newsletter-signup-topnav')
        }
      });
    }
  }

  setDailyCookie() {
    if (window.localStorage.needToSetDailyCookie) {
      Cookies.set('dailycookie', 'true', {
        expires: 1,
        domain: '.fastcompany.com'
      });

      window.localStorage.removeItem('needToSetDailyCookie');
    }
  }

  closeNotification(key) {
    localStorage.setItem(key, true);

    this.setState({
      notification: {
        message: '',
        callback: () => {}
      }
    });
  }

  closeNewsletterBar(key) {
    localStorage.setItem(key, true);

    this.setState({
      topNewsletterSignup: {
        show: false,
        callback: () => {}
      }
    });
  }

  livereload(env) {
    if (env === 'development') {
      return <script src="//fastcompany.local:35729/livereload.js" defer />;
    }
    return '';
  }

  scriptbundle(env) {
    if (env === 'development') {
      return [
        <script
          key="vendor"
          src="//fastcompany.local:3001/static/vendor.js"
          defer
        />,
        <script
          key="bundle"
          src="//fastcompany.local:3001/static/app.js"
          defer
        />
      ];
    }

    if (env === 'test') {
      return [
        <script
          key="vendor"
          src={`/js/${this.props.manifestJSON['vendor.js']}`}
          defer
        />,
        <script
          key="bundle"
          src={`/js/${this.props.manifestJSON['app.js']}`}
          defer
        />
      ];
    }

    return [
      <script
        key="vendor"
        src={`${this.props.manifestJSON['vendor.js']}`}
        defer
      />,
      <script key="bundle" src={`${this.props.manifestJSON['app.js']}`} defer />
    ];
  }

  scriptChunks(env) {
    if (env === 'development') {
      return null;
    }

    if (env === 'test') {
      return this.chunks.map(chunk => (
        <script
          key={chunk}
          src={`/js/${this.props.manifestJSON[`${chunk}.js`]}`}
          defer
        />
      ));
    }

    // dhm3b9kzksoul.cloudfront.net/bundle/js/23.bundle.948afa47006e5ca983f3.js
    return this.chunks.map(chunk => (
      <script
        key={chunk}
        src={`${this.props.manifestJSON[`${chunk}.js`]}`}
        defer
      />
    ));
  }

  analyticsHeader() {
    const listOfAnalytics = [
      'datalayer',
      'gpt',
      'camp',
      'blueconic',
      'nielsen',
      'googleAnalytics',
      'facebookPixel',
      'bombora',
      'piano',
      'pinterest',
      'pushly',
      'adlightning'
    ];

    return <Analytics location="header" listOfAnalytics={listOfAnalytics} />;
  }

  analyticsFooter(isPreview = false) {
    // eslint-disable-next-line class-methods-use-this
    const listOfAnalytics = [
      'structuredData',
      'nielsen',
      'skimlinks',
      'queryly',
      'chartbeat',
      'knotch'
    ];

    if (!isPreview) listOfAnalytics.push('parsely');

    return <Analytics location="footer" listOfAnalytics={listOfAnalytics} />;
  }

  es6Polyfill() {
    return (
      <Script
        type="text/javascript"
        src="https://polyfill-fastly.io/v2/polyfill.js?features=es6"
      />
    );
  }

  hideFooter(url) {
    if (this.pathname && this.pathname.includes(url)) {
      this.setState({ hasFooter: false });
    }
  }

  render() {
    const { location, pageType, recircFeed, config } = this.props;
    const env = get(config, 'env', '');
    const site = get(config, 'site', '');

    const pagePath = this.pathname.slice(1).split('/');

    // Default values for showing or hiding various elements
    const displayOptions = {
      masthead: {
        show: true,
        logoOnly: false,
        hideSubscribeBtn: false,
        hideCategories: false
      },
      recirc: get(this.props, 'recircFeed.hide', true) || true
    };

    let hideFooterAd = false;

    if (
      this.pathname === '/most-innovative-companies' ||
      this.pathname === '/innovation-by-design/list' ||
      this.pathname === '/innovation-by-design-hub' ||
      this.pathname === '/brands-that-matter/list' ||
      this.pathname === '/world-changing-ideas/list' ||
      this.pathname === '/world-changing-ideas' ||
      this.pathname === '/best-creators-2022' ||
      this.pathname === '/newsletters' ||
      this.pathname === '/premium' ||
      this.pathname === '/subscribe' ||
      this.pathname === '/apply/next-big-things-in-tech' ||
      this.pathname === '/apply/most-innovative-companies' ||
      this.pathname === '/apply/best-workplaces-for-innovators' ||
      this.pathname === '/apply/world-changing-ideas' ||
      this.pathname === '/apply/innovation-by-design' ||
      this.pathname === '/apply/brands-that-matter' ||
      this.pathname === '/most-creative-people'
    ) {
      displayOptions.recirc = false;
    }

    if (
      this.pathname === '/newsletters' ||
      this.pathname === '/premium' ||
      this.pathname === '/subscribe' ||
      this.pathname === '/apply/next-big-things-in-tech' ||
      this.pathname === '/apply/most-innovative-companies' ||
      this.pathname === '/apply/best-workplaces-for-innovators' ||
      this.pathname === '/apply/world-changing-ideas' ||
      this.pathname === '/apply/innovation-by-design' ||
      this.pathname === '/apply/brands-that-matter' ||
      this.pathname === '/lists' ||
      this.pathname === '/innovation-by-design/list' ||
      this.pathname === '/groupsubscriptions'
    ) {
      hideFooterAd = true;
    }

    // Force hide Recirc unit by path
    const noRecircPathnames = [
      'subscribe',
      'offer',
      'digital',
      'employeegift',
      'gift',
      'holidaysale',
      'jointoday',
      'international',
      'redeem',
      'students',
      'most-innovative-companies',
      'verify',
      'sxsw2024'
    ];

    noRecircPathnames.forEach(path => {
      if (path === pagePath[0]) displayOptions.recirc = false;
    });

    let activeCategory = '';

    if (
      this.props.pageType === 'category' ||
      this.props.pageType === 'videoIndexPage' ||
      this.props.pageType === 'tag'
    ) {
      activeCategory = location.pathname;
    }

    let addedSpacingElements = '';

    if (
      this.state.topNewsletterSignup.show &&
      this.state.notification.message
    ) {
      addedSpacingElements = 'layout--notification-and-newsletter';
    }

    if (this.state.notification.message) {
      addedSpacingElements = 'layout--notification';
    }

    const specialUrl = () => {
      switch (location.pathname) {
        case '/section/innovation-festival-360':
          return 'innovation-festival-360';
        case '/queer-50/2021':
          return 'queer-50-2021';
        case '/most-creative-people/list':
          return 'mcp-2023';
        case '/innovation-by-design/list':
          return 'ibd-2024';
        default:
          return '';
      }
    };

    // https://mansueto.atlassian.net/browse/ENG-2545
    if (this.pathname === '/subscribe') {
      displayOptions.masthead.hideSubscribeBtn = true;
      displayOptions.masthead.hideCategories = true;
    } else {
      displayOptions.masthead.hideSubscribeBtn = false;
      displayOptions.masthead.hideCategories = false;
    }

    if (this.pathname === '/innovation-by-design/list') {
      displayOptions.masthead.hideCategories = true;
    }

    if (this.pathname === '/next-big-things-in-tech/list') {
      displayOptions.recirc = false;
    }

    if (get(this.props, 'pageType', '') === 'applyPage') {
      displayOptions.masthead.logoOnly = true;
      displayOptions.recirc = false;
      if (
        get(this.props, 'location', '')?.pathname.includes(
          'innovation-by-design'
        )
      ) {
        displayOptions.masthead.forceCenter = true;
      }
    }

    if (get(this.props, 'pageType', '') === 'bestWorkplacesPage') {
      displayOptions.masthead.logoOnly = false;
      displayOptions.recirc = false;
    }

    return (
      <React.Fragment>
        <Helmet>
          <body
            className={`layout ${
              pageType ? `layout--${pageType}` : ''
            }${addedSpacingElements} ${site} ${specialUrl()}`}
          />
        </Helmet>
        {/* START GOOGLE TAG MANAGER NOSCRIPT */}
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-KM6J9JF"
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </noscript>
        {/* END GOOGLE TAG MANAGER NOSCRIPT */}

        {this.analyticsHeader()}

        <div id="ZN_2t6mDnUjRUuS8jH" />
        <Masthead
          isHome={location.pathname === '/'}
          activeCategory={activeCategory}
          config={config}
          notification={this.state.notification}
          topNewsletterSignup={this.state.topNewsletterSignup}
          {...navContext[site]}
          homepageTopPromo={this.props.homepageTopPromo}
          displayOptions={displayOptions.masthead}
        />

        <section className="main" role="main">
          <ErrorBoundary
            onError={(error, info) => {
              log.error(
                `Web Application Error: ${JSON.stringify(
                  error
                )}, ${JSON.stringify(info)}`
              );
            }}
            errorComponent={<DefaultError />}
          >
            {renderRoutes(this.props.route.routes)}
          </ErrorBoundary>
          {!hideFooterAd && (
            <div className="footer-ad-container">
              <AdContainer type="footer" />
            </div>
          )}
          {displayOptions.recirc && (
            <div className="recirc__container">
              <Recirc config={config} feed={recircFeed} />
            </div>
          )}
        </section>
        {this.state.hasFooter && <Footer />}
        {this.analyticsFooter(this.isPreview)}
        {this.livereload(env)}
        <Config />
        {/* {this.scriptbundle(env)} */}
        {/* {this.scriptChunks(env)} */}
        <div id="linkedInBottomRibbon" className="fixed-bar--bottom" />
        <PianoBottomBanner />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    config: state.config,
    manifestJSON: state.config.manifestJSON,
    staticVendorUrl: state.config.staticVendorUrl,
    staticBundleUrl: state.config.staticBundleUrl,
    pageType: state.context.type || '',
    recircFeed: {
      hide: get(state, 'recircFeed.hide'),
      fastcompany: get(state, 'recircFeed.data.fastcompany') || [],
      design: get(state, 'recircFeed.data.design') || [],
      tech: get(state, 'recircFeed.data.tech') || [],
      news: get(state, 'recircFeed.data.news') || [],
      worklife: get(state, 'recircFeed.data.worklife') || []
    },
    postFinite: get(state, 'postFinite.data.posts') || [],
    homepageTopPromo: get(state, 'homepageTopPromo.data', {})
  };
}

Layout.propTypes = {
  route: PropTypes.shape({
    routes: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  config: PropTypes.shape({}).isRequired,
  manifestJSON: PropTypes.shape({
    'vendor.js': PropTypes.string,
    'app.js': PropTypes.string
  }).isRequired,
  staticBundleUrl: PropTypes.string.isRequired,
  staticVendorUrl: PropTypes.string.isRequired,
  pageType: PropTypes.string.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
  recircFeed: PropTypes.shape({
    hide: PropTypes.bool,
    fastcompany: PropTypes.arrayOf(PropTypes.shape({})),
    tech: PropTypes.arrayOf(PropTypes.shape({})),
    design: PropTypes.arrayOf(PropTypes.shape({})),
    news: PropTypes.arrayOf(PropTypes.shape({})),
    workLife: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  homepageTopPromo: PropTypes.shape({})
};

Layout.defaultProps = {
  homepageTopPromo: {}
};

export default withRouter(connect(mapStateToProps)(Layout));
